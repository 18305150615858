import React from "react";

const TextArea = ({name,placeholder, border}) => {
    return (
      <div className="relative rounded-md">
        <textarea
        name={name}
            className={`form-control h-full text-gray-700 block w-full px-4 py-[0.7rem] ${border} border-gray-300 rounded-md focus:ring-0 focus:border-transparent`}
          placeholder={placeholder}
          rows={2}
        />
      </div>
    );
  };

  export default TextArea