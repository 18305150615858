import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import Button7 from "../buttons/button7/button7";
import InputField from "../inputs/input";
import { PhoneNum } from "../inputs/phoneNum";
import TextArea from "../inputs/textArea";
import SwipeSlider from "../sections/home-section/slider/slider";
import CustomCarousel from "../carousel/custom/CustomCarousel";
import { TransitionCard } from "../card/DownUpTransition/TransitionCard";
import { Framer } from "../animations/framerSection";
import { ContactCard } from "../sections/home-section/contactForm1/contact-card";
import ModalFormat from "../modal/modalFormat";
import Marquee from "react-fast-marquee";
import { ContactForm1 } from "../sections/home-section/contactForm1/contactForm1";
import { MarqueeReviewCard } from "../card/reviewCard/ReviewCard";
import { Link } from "react-scroll";

export default function AppDevelopment() {
  const form = useRef();
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const items = ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"];
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  const handleIntersection = (entries) => {
    const entry = entries[0];
    setIsVisible(entry.isIntersecting);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true); // Show loading message

    emailjs
      .sendForm(
        "service_iuxkzyp",
        "template_bj7guia",
        form.current,
        "-WyqEPrHNBiPDelfN"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSending(false);
          setIsSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Add a window resize event listener to update isMobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const CarouselItems = [
    {
      slideImg: "/assets/mobileCarousel/screen/payment-g-1.png",
      indicatorImg: "/assets/mobileCarousel/mobile-multiple-payment.png",
      label: "Multiple Payment",
    },
    {
      slideImg: "/assets/mobileCarousel/screen/advnc-2.png",
      indicatorImg: "/assets/mobileCarousel/mobile-analysis.png",
      label: "Advanced Analytics",
    },
    {
      slideImg: "/assets/mobileCarousel/screen-mobile-friendly.png",
      indicatorImg: "/assets/mobileCarousel/mobile-friendly.png",
      label: "Mobile Friendly",
    },
    {
      slideImg: "/assets/mobileCarousel/screen/lang.png",
      indicatorImg: "/assets/mobileCarousel/mobile-multi-language.png",
      label: "Multi-Language",
    },
    {
      slideImg: "/assets/mobileCarousel/screen/voucher.png",
      indicatorImg: "/assets/mobileCarousel/mobile-discounts.png",
      label: "Discounts & Promo",
    },
    {
      slideImg: "/assets/mobileCarousel/screen/review.png",
      indicatorImg: "/assets/mobileCarousel/mobile-reviews.png",
      label: "Reviews & Ratings",
    },
    {
      slideImg: "/assets/mobileCarousel/screen/location.png",
      indicatorImg: "/assets/mobileCarousel/mobile-geo-location.png",
      label: "Geo Location",
    },
    {
      slideImg: "/assets/mobileCarousel/screen/notification.png",
      indicatorImg: "/assets/mobileCarousel/mobile-notification.png",
      label: "Push Notification",
    },
    {
      slideImg: "/assets/mobileCarousel/screen/chat-integration.png",
      indicatorImg: "/assets/mobileCarousel/mobile-chat.png",
      label: "Integrated Chat System",
    },
  ];
  const GoogleReviews = [
    {
      clientImg: "/assets/clientLogo/Parmeet.png",
      ClientName: "A36 Parmeet",
      content:
        "Solutions 1313 recently designed our company's website and is eager to say it was a tremendous success. Throughout the whole process, we worked with Mr. Amrinder Singh, the owner of the company. He and his team deserve a lot of credit for meticulously managing as well as implementing our requests. Their customer service and management skills are the best.",
    },
    {
      clientImg: "/assets/clientLogo/Jitender.png",
      ClientName: "Jitender Kumar",
      content:
        "Met the owner of Easy-Web-Plan a year back for my software. Since then there has been no looking back for my business. The best mlm software development company in mohali. Bug free software, regular updates by them. Thanks",
    },
    {
      clientImg: "/assets/clientLogo/Akhlaq.png",
      ClientName: "Mohd Akhlaq",
      content:
        "Easy-Web-Plan providing the best digital marketing course in Mohali. I got myself a job in Easy-Web-Plan after completing my course and training. Highly recommended. The best digital marketing institute in Mohali. I am so happy. Thanks",
    },
    {
      clientImg: "/assets/clientLogo/suraj.png",
      ClientName: "Suraj Sanjay",
      content:
        "I own a business and i got myself their digital marketing course. They provide indeed the best digital marketing course in mohali. Highly recommended for people who own their own business to go for their course. We can also say that solution1313 is the best digital marketing institute in Chandigarh",
    },
    {
      clientImg: "/assets/clientLogo/Davinder.png",
      ClientName: "Davinder",
      content:
        "Best mobile app development company in Mohali. They built an app for my business and it's the best thing until now. So smooth functionality and the best development",
    },
    {
      clientImg: "/assets/clientLogo/Baljeet.png",
      ClientName: "Baljit Singh",
      content:
        "Best ecommerce website development company in mohali. Got my clothing website made from them. Also got my marketing done from them and they are just amazing. Best website designing company in mohali.  Best digital marketing company in mohali. Highly recommend",
    },
    {
      clientImg: "/assets/clientLogo/Joginder.png",
      ClientName: "Joginder Pal",
      content:
        "Thanks Easy-Web-Plan for giving me the best digital marketing course in mohali. I was searching from a very long time. And my search ended with the best. The best digital marketing company in mohali",
    },
    {
      clientImg: "/assets/clientLogo/sahajpal.png",
      ClientName: "Sahajpal Brar",
      content:
        "Got my mlm software developed from them and they are so punctual on deadlines and everything. The best mlm software development company in mohali, chandigarh. Highly recommended to everyone looking For the same",
    },
    {
      clientImg: "/assets/clientLogo/himanshu.png",
      ClientName: "Himanshu Sagar",
      content:
        "Best website development company in mohali. Customised designs, quick service and such a great attention to detail. Best services by Easy-Web-Plan. Thankyou team.",
    },
  ];
  const servicesItems = [
    {
      src: "/assets/apple-logo.png",
      label: "IOS App Development",
      bgColor: "bg-[#000]",
    },
    {
      src: "/assets/android-logo.png",
      label: "Android App Development",
      bgColor: "bg-[#14b93b]",
    },
    {
      src: "/assets/web-logo.webp",
      label: "Web App Development",
      bgColor: "bg-[#0071da]",
    },
    {
      src: "/assets/hybrid-logo.png",
      label: "Hybrid-Native App Development",
      bgColor: "bg-[#102b92]",
    },
  ];
  const testimonials = [
    {
      id: 1,
      name: "John Doe",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageUrl: "/assets/testimonial-avatar-1.webp",
    },
    {
      id: 2,
      name: "Jane Smith",
      text: "Suspendisse potenti. Nulla facilisi. Sed at mauris in lorem.",
      imageUrl: "/assets/testimonial-avatar-1.webp",
    },
    {
      id: 3,
      name: "Jane Smith",
      text: "Suspendisse potenti. Nulla facilisi. Sed at mauris in lorem.",
      imageUrl: "/assets/testimonial-avatar-1.webp",
    },
    // Add more testimonials here
  ];

  const Clients = [
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
    { imgSrc: "/assets/bharatpe.webp" },
  ];
  const meetingService = [
    { label: "Masala Chai", id: "option1" },
    { label: "Green Chai", id: "option2" },
    { label: "Sugarfree Chai", id: "option3" },
    { label: "Coffee", id: "option4" },
    { label: "Water", id: "option5" },
    { label: "Virtual Chai", id: "option6" },
  ];
  const clientsToDisplay = isMobile ? Clients.slice(0, 12) : Clients;
  const FlipCardItems = [
    {
      imgSrc: "/assets/whyChoose/free-cost.png",
      label: "Free Cost-Analysis",
      content:
        "Get free cost-analysis & excellent pieces of advice on your Mobile app development idea from industry experts",
    },
    {
      imgSrc: "/assets/whyChoose/skilled.png",
      label: "Super-Skilled Developers",
      content:
        "Experienced developers who are technically competent to handle any level of complex app development projects",
    },
    {
      imgSrc: "/assets/whyChoose/beautiful-app.png",
      label: "Beautiful App Designs",
      content:
        "Our Dedicated in-house design studio helps in creating stunning designs for your business apps",
    },
    {
      imgSrc: "/assets/whyChoose/superior.png",
      label: "Superior Quality",
      content:
        "Our extensive QA & testing process ensures that the apps are of supreme quality and free from bugs",
    },
    {
      imgSrc: "/assets/whyChoose/easy-to-use.png",
      label: "Easy-to-Use Apps",
      content:
        "We create apps that are exceptionally robust & easy-to-use. Get reliably developed apps for your business",
    },
    {
      imgSrc: "/assets/whyChoose/full-custom.png",
      label: "Fully Customized Apps",
      content:
        "Get your apps developed the way you like it. Build apps that are Personalized to your business needs",
    },
    {
      imgSrc: "/assets/whyChoose/hand-holding.png",
      label: "Hand-Holding Approach",
      content:
        "Get proper industry insights with the right guidance. Let industry experts hand hold you to success",
    },
    {
      imgSrc: "/assets/whyChoose/support.png",
      label: "24x7 Customer Support",
      content:
        "Our 24*7 dedicated customer support ensures you get technical assistance whenever necessary",
    },
  ];
  const servicesItems1 = [
    {
      bgImg: "/assets/app-dev-easy/custome.jpg",
      label: "Full Customization",
      frontImg: "/assets/app-dev-easy/custome.png",
    },
    {
      bgImg: "/assets/app-dev-easy/phone.jpg",
      label: "White Label",
      frontImg: "/assets/app-dev-easy/label-2.png",
    },
    {
      bgImg: "/assets/app-dev-easy/sourcecod.jpeg",
      label: "Complete Source Code",
      frontImg: "/assets/app-dev-easy/code.png",
    },
  ];

  return (
    <>
      
      <div className="">
        <section className="app-cover-bg 3xl:h-[98vh] flex pb-[3rem]">
          <div className="container1 ">
            <div className="flex flex-col gap-y-[1.5rem] lg:flex-row pt-[150px] lg:pt-[180px]  lg:pb-[50px]">
              <div
                style={{ transition: "0.3s" }}
                className="w-full lg:w-1/2 order-2 lg:order-1 text-white leading-[3rem] flex items-center"
              >
                <div className="mt-[2rem] lg:mt-0 text-center lg:text-left">
                  <h2
                    style={{ letterSpacing: "-1px", lineHeight: 1.3 }}
                    className="font-SF-Pro-Regular text-[26px] lg:text-[34px]"
                  >
                    Experience A World-Class <br />{" "}
                    <span className="font-SF-Pro-Semibold">
                      Mobile App Development Service
                    </span>
                  </h2>

                  <p
                    style={{ fontWeight: "500" }}
                    className="font-helvetica text-white text-[20px] leading-8"
                  >
                    Build Affordable, Easy-to-use & Feature-Rich Mobile Apps For
                    Your Business
                  </p>

                  <a onClick={() => setIsOpen(true)}>
                    <Button7 text={"Book A Free Demo"} />
                  </a>
                  {/* <button
                    style={{ borderRadius: "4px", transition: "0.3s" }}
                    className="px-[28px] font-SF-Pro-Semibold py-[8px] bg-[#1ba37e] hover:bg-white hover:text-black text-[20px]"
                  >
                    Book A Free Demo
                  </button> */}
                </div>
              </div>
              <div
                style={{ transition: "0.3s" }}
                className="w-full lg:w-1/2 order-1 lg:order-2 flex items-center justify-end"
              >
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="w-full lg:w-[85%] bg-white rounded-lg p-6 lg:px-10 lg:py-10"
                >
                  <h2 className="text-center text-black font-SF-Pro-Regular text-[20px] lg:text-[26px]">
                    {" "}
                    Let's Build Your{" "}
                    <span className="font-Gilroy-Bold">Dream App!</span>
                  </h2>
                  <div className="mt-[1rem]  lg:mt-[1.2rem] flex flex-col gap-y-[0.7rem]">
                    <InputField
                      name="from_name"
                      placeholder={"First Name"}
                      id={"firstName"}
                      border={"border-b"}
                    />
                    <InputField
                      name={"from_email"}
                      placeholder={"Email"}
                      id={"email"}
                      border={"border-b"}
                    />

                    <PhoneNum name={"from_phnNum"} />
                    <TextArea
                      name={"message"}
                      placeholder={"What's your Project about?"}
                      border={"border-b"}
                    />
                  </div>

                  <div className="mt-[2rem]">
                    <button
                      type="submit"
                      style={{ transition: "0.5s", borderRadius: "3px" }}
                      className="border-[1px] border-[#1aa37e] bg-[#1aa37e] text-white text-[18px] py-[8px] px-[43px] hover:text-[#1aa37e] hover:bg-white"
                    >
                      {isSending ? "Please Wait..." : "Submit"}
                    </button>
                  </div>
                </form>
                {/* <ContactCard/> */}
              </div>
            </div>
          </div>
        </section>
        <section className="mt-0 lg:mt-[3rem] 3xl:mt-[-5rem] container1 ">
          <div className="flex flex-col gap-y-[2rem] sm:gap-y-[5rem]">
            <div
              style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
              className="bg-white flex flex-col lg:flex-row justify-between rounded-md px-[2rem] py-[3rem]"
            >
              <div className="mb-[2rem] lg:mb-0 w-full lg:w-[40%] cxl:w-[43%] flex justify-center lg:justify-start items-center">
                <div className="text-black leading-[2.5rem] flex flex-col items-center lg:items-start">
                  <h2 className="text-center lg:text-left font-SF-Pro-Regular text-[26px] leading-[1.2] lg:text-[30px]">
                    Vast Mobile App Development{" "}
                    <span className="font-SF-Pro-Semibold text-[#2c2c2c]">
                      Services We Offer
                    </span>
                  </h2>

                  <div className="flex gap-x-[0.4rem] mt-3">
                    <span className="bg-[#fdc468] w-[14.5px] h-[14px] rounded-full"></span>
                    <span className="bg-[#fed79b] w-[14.5px] h-[14px] rounded-full"></span>
                    <span className="bg-[#fee1b4] w-[14.5px] h-[14px] rounded-full"></span>
                    <span className="bg-[#fff5e6] w-[14.5px] h-[14px] rounded-full"></span>
                  </div>
                </div>
              </div>
              {/* <button click={openModal}></button> */}
              <div className="w-full lg:w-[60%] cxl:w-[57%]">
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-[14px] gap-y-[15px]">
                  {servicesItems.map((item, index) => (
                    <Link
                      to="contact"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      key={index}
                      style={{
                        transition: "0.4s",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                      }}
                      className={`cursor-pointer col-span-1  rounded-md  px-[0.5rem] py-[1rem]  hover:scale-[1.03] ${item.bgColor}`}
                    >
                      <div className="w-full flex justify-center mb-4">
                        <Framer entrance={"bottom"}>
                          <img className="active-img" src={item.src}></img>
                          {/* <TfiApple className="text-white text-center text-[60px]" /> */}
                        </Framer>
                      </div>
                      <span
                        style={{
                          letterSpacing: "1px",
                          lineHeight: "1.3",
                        }}
                        className="font-SF-Pro-Semibold flex justify-center text-white text-center text-[15px]"
                      >
                        <Framer entrance={"top"}>{item.label}</Framer>
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-x-[0.5rem] gap-y-[0.5rem]  justify-between">
              {servicesItems1.map((item, index) => (
                <div
                  onClick={() => setIsOpen(true)}
                  key={index}
                  style={{ backgroundImage: `url(${item.bgImg})` }}
                  className={`cursor-pointer rounded-2xl w-full sm:w-[31.5%] px-[30px] py-[30px] lg:p-[70px] relative overflow-hidden  bg-center bg-cover`}
                >
                  {/* <img className="relative w-full" src={item.bgImg} /> */}
                  <div className=" flex flex-col  items-center justify-center gap-y-[0.5rem]">
                    <Framer entrance={"bottom"}>
                      <div>
                        <img
                          className="h-[60px] sm:h-[80px]"
                          src={item.frontImg}
                        ></img>
                      </div>
                    </Framer>
                    <Framer entrance={"top"}>
                      <p
                        style={{
                          textAlign: "center",
                          lineHeight: "1.3",
                          margin: "1rem 0 0 0",
                        }}
                        className="text-white text-center xl:whitespace-nowrap w-fit font-SF-Pro-Semibold text-[18px] z-10"
                      >
                        {item.label}
                      </p>
                    </Framer>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Short Info Black BG*/}
        <section className="bg-black dot-bg-cover py-[40px] lg:py-[80px] mt-[5rem]">
          <div className="container text-white text-center justify-center items-center flex flex-col gap-[1rem]">
            <div className=" flex flex-col items-center gap-y-[1.5rem]">
              <h3 className="text-[21px] lg:text-[26px] leading-[1.4] font-SF-Pro-Regular">
                Take Your Business to Next Level With Our Mobile App Development
                Service
              </h3>
              <button
                onClick={() => setIsOpen(true)}
                style={{ transition: "0.5s" }}
                className="font-SF-Pro-Semibold text-[18px] lg:text-[20px] text-white hover:text-black border-[2px] border-[#1aa37e] bg-[#1aa37e] hover:bg-white rounded-xl py-[10px] lg:py-[15px] px-[20px] lg:px-[60px]"
              >
                Get Started
              </button>
              {/* <Button5 text={"Partner With Us"} /> */}
            </div>
          </div>
        </section>

        {/* Grid Collage */}
        <section className="py-[3rem] lg:py-[5rem] container flex flex-col gap-[3rem]">
          <h2
            style={{ letterSpacing: "-0.6px", fontWeight: "600" }}
            className="leading-[1.2] text-center text-[#2a2a2a] font-SF-Pro-Semibold text-[26px] lg:text-[30px]"
          >
            {/* <b> */}
            Fully Customized Mobile App Development Services For Your Business
            {/* </b> */}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 flex-wrap justify-evenly gap-[2rem] px-[1.5rem]">
            {/* Part 1 */}
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`col-span-1 lg:col-span-1 relative rounded-xl overflow-hidden cursor-pointer`}
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/on-demand.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/on-demand-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-[rgba(0,140,238,.7)] z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  On-Demand App Solution
                </h4>
              </Framer>
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`col-span-1 lg:col-span-1 relative rounded-xl overflow-hidden cursor-pointer`}
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/ecommerce-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/ecommerce-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Ecommerce & Marketplace Solution
                </h4>
              </Framer>
            </Link>

            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-2 relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip"
                  src="/assets/food-beverage.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/food-beverage-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Food & Beverage Solution
                </h4>
              </Framer>
            </Link>
            {/* Part 2*/}
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-2  relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip"
                  src="/assets/pickup-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/pickup-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Pickup & Delivery Solution
                </h4>
              </Framer>
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-1  relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/blockchain-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/blockchain-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Blockchain Development
                </h4>
              </Framer>
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-1  relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/IOT-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/IOT-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Internet of Things (IOT)
                </h4>
              </Framer>
            </Link>

            {/* Part 3 */}
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-1 relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/Social-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/social-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-[rgba(0,140,238,.7)] z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Social Networking Solution
                </h4>
              </Framer>
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-1 relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/healthcare-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/healthcare-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Healthcare & Medical Solution
                </h4>
              </Framer>
            </Link>

            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-2 relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip"
                  src="/assets/laundry-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/laundry-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Laundry & Home Services
                </h4>
              </Framer>
            </Link>
            {/* Part 4*/}
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-2  relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip"
                  src="/assets/transport-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/transport-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Transportation Solution
                </h4>
              </Framer>
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-1  relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/Real-Estate-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/real-estate-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Real Estate Solution
                </h4>
              </Framer>
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-1  relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/banking-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/finance-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Banking & Finance
                </h4>
              </Framer>
            </Link>

            {/* Part 5 */}
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-1 relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/education-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/education-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-[rgba(0,140,238,.7)] z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Education & Learning
                </h4>
              </Framer>
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-1 relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip "
                  src="/assets/manufacturing-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/manufacture-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Manufacturing Business Solution
                </h4>
              </Framer>
            </Link>

            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="col-span-1 lg:col-span-2 relative rounded-xl overflow-hidden cursor-pointer"
            >
              <Framer>
                <img
                  className="relative h-[250px] object-cover w-full overflow-clip"
                  src="/assets/enterprise-bg.webp"
                />
                <img
                  className="absolute z-20 top-[30px] left-[30px] w-[3rem]"
                  src="/assets/enterprise-sublogo.png"
                ></img>
                <h4
                  style={{ transition: "0.3s" }}
                  className="flex items-center text-white bg-black/50 hover:bg-blue-600/70 z-10 top-0 bottom-0 left-0 right-0 absolute font-semibold font-Gilroy-Bold text-[24px] pt-[100px] px-[30px] pb-[30px]"
                >
                  Enterprise Mobility Solution
                </h4>
              </Framer>
            </Link>
          </div>
        </section>

        {/* Short Info Gray BG*/}
        <section className="bg-[#f2f2f2] py-[80px] ">
          <div className="container text-black text-center justify-center items-center flex flex-col gap-[1rem]">
            <div
              style={{ lineHeight: 1 }}
              className="font-SF-Pro-Regular flex flex-col items-center leading-7 gap-y-[1rem]"
            >
              <h3 className="text-[22px] lg:text-[26px] font-SF-Pro-Semibold text-[#000] ">
                Didn't find your Industry Solution?
              </h3>

              <h3
                style={{ fontWeight: "400", lineHeight: 1.3 }}
                className="text-[22px] lg:text-[26px] leading-10"
              >
                Discuss your mobile app development requirements with our team &
                get the finest fit for your business
              </h3>
              <button
                onClick={() => setIsOpen(true)}
                style={{ transition: "0.5s" }}
                className="text-[20px] mt-6 font-SF-Pro-Semibold text-white hover:text-black border-[2px] border-[#1aa37e] bg-[#1aa37e] hover:bg-white rounded-xl py-[25px] px-[60px]"
              >
                Get Started
              </button>
              {/* <Button5 text={"Partner With Us"} /> */}
            </div>
          </div>
        </section>

        {/* SwiperSlider */}
        <section className="mt-[3rem] lg:mt-[5rem]">
          <h2 className="container font-SF-Pro-Semibold text-center text-[30px] pb-[1rem]">
            Portfolio
          </h2>
          <SwipeSlider />
        </section>

        {/* Short Info Black BG*/}

        <section className="bg-black dot-bg-cover py-[80px] ">
          <div className="container text-white text-center justify-center items-center flex flex-col gap-[1rem]">
            <div className=" flex flex-col items-center gap-y-[1.5rem]">
              <h3 className="text-[22px] lg:text-[26px] leading-10 font-SF-Pro-Regular">
                Trust the global leaders in mobile solution for transforming
                your ideas
              </h3>
              <button
                onClick={() => setIsOpen(true)}
                style={{ transition: "0.5s" }}
                className="font-SF-Pro-Semibold text-[18px] lg:text-[20px] text-white hover:text-black border-[2px] border-[#1aa37e] bg-[#1aa37e] hover:bg-white rounded-xl py-[10px] lg:py-[15px] px-[20px] lg:px-[60px]"
              >
                Get Started
              </button>
              {/* <Button5 text={"Partner With Us"} /> */}
            </div>
          </div>
        </section>
        {/* 3 cards zoom-in */}
        <section className="py-[40px] lg:py-[80px]  flex flex-col gap-y-[3rem]">
          <div style={{ lineHeight: "1" }} className="container1">
            <h2 className="text-center text-[#000] py-0 my-0 font-SF-Pro-Semibold text-[26px] lg:text-[30px]">
              Our Quality Works Get Featured
            </h2>
            <p className="mt-[15px] font-SF-Pro-Regular leading-[1.3] text-black text-[20px] lg:text-[24px] text-center">
              We Have Been Acknowledged A Number Of Times For Top-Notch Digital
              Solution
            </p>
          </div>
          <div className="container">
            <Framer>
              <ul className=" relative flex flex-col lg:flex-row gap-[2rem]">
                <li className="w-full lg:w-[60%] flex flex-col justify-between gap-[2rem]">
                  <div className="feature-card flex flex-col lg:flex-row overflow-hidden">
                    <div className="w-full lg:w-1/2 h-[240px] overflow-hidden">
                      <img
                        className="h-[240px] w-full object-cover"
                        src="/assets/businessOfApp.webp"
                      ></img>
                    </div>
                    <div
                      style={{ letterSpacing: "1px" }}
                      className="text-center lg:text-left font-SF-Pro-Regular w-full lg:w-1/2 bg-[#d4a44c] text-white py-[1.5rem] px-[1rem] lg:h-[240px]"
                    >
                      <h3 className="text-[22px] font-SF-Pro-Semibold">
                        Business of Apps
                      </h3>
                      <p className="my-[10px] leading-[1.2] text-[18px] font-normal">
                        <span className="font-SF-Pro-Semibold">
                          "Easy-Web-Plan
                        </span>{" "}
                        is a mobile focused software development company which
                        has served startups and enterprises all across the
                        globe"
                      </p>
                    </div>
                  </div>

                  <div className="feature-card flex flex-col lg:flex-row overflow-hidden">
                    <div
                      style={{ letterSpacing: "1px" }}
                      className="text-center order-2 lg:order-1 lg:text-left font-SF-Pro-Regular w-full lg:w-1/2 bg-[#e5eef2] text-black py-[1.5rem] px-[1rem] lg:h-[240px]"
                    >
                      <h3 className="text-[22px] font-SF-Pro-Semibold">
                        Bitcoin Insider
                      </h3>
                      <p className="my-[10px] leading-[1.2] text-[18px] font-normal">
                        "Offers continuous delivery of Android development
                        services. Hence, still growing in the market"
                      </p>
                    </div>
                    <div className="w-full order-1 lg:order-2 lg:w-1/2 h-[240px] overflow-hidden">
                      <img
                        className="h-[240px] w-full object-cover"
                        src="/assets/bitcoin-insider.webp"
                      ></img>
                    </div>
                  </div>
                </li>

                <li className=" lg:w-[40%] overflow-hidden">
                  <div className="feature-card h-full">
                    <div className="h-[240px] overflow-hidden">
                      <img
                        className="h-[240px] w-full object-cover"
                        src="/assets/digital-nyc.webp"
                      ></img>
                    </div>
                    <div
                      style={{ letterSpacing: "1px" }}
                      className="text-center lg:text-left font-SF-Pro-Regular bg-[#19325f] h-full text-white py-[1.5rem] px-[1.8rem]"
                    >
                      <h3 className="text-[22px] font-SF-Pro-Semibold">
                        Business of Apps
                      </h3>
                      <p className="leading-[1.8] text-[18px] font-SF-Pro-Regular">
                        <span className="font-SF-Pro-Semibold">
                          "Easy-Web-Plan,
                        </span>{" "}
                        a best mobile app development company in the USA has is
                        proficient and effective in handling all the development
                        problems"
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </Framer>
          </div>
        </section>

        {/*Mobile Custom Carousel */}
        <section className="">
          <div className="container1" style={{ lineHeight: "1" }}>
            <h2 className="text-center text-[#000] py-0 leading-[1.3] my-0 font-SF-Pro-Regular font-semibold text-[26px] lg:text-[30px]">
              A Complete Feature-Suite To Build Your Custom Mobile Apps
            </h2>
            <p className="text-black font-SF-Pro-Regular leading-[1.2] mt-[15px] text-[20px] lg:text-[24px] text-center">
              We offer end-to-end features to drive engagement & revenue for
              your brand
            </p>
          </div>
          <div className="container1 py-[2rem]  lg:pb-[4rem]">
            <CustomCarousel items={CarouselItems} indicators={items} />
          </div>
        </section>

        {/* Short Info Blue BG*/}
        <section className="dot-bg-blue py-[40px] lg:py-[80px] ">
          <div className="container text-white text-center justify-center items-center flex flex-col gap-[1rem]">
            <div className=" flex flex-col items-center gap-y-[1.5rem]">
              <h3
                // style={{ fontWeight: "800" }}
                className="text-[22px] lg:text-[26px] leading-10 font-SF-Pro-Regular"
              >
                Customized{" "}
                <span className="font-SF-Pro-Semibold">
                  {" "}
                  Mobile App Development Services
                </span>{" "}
                For Your Business Goals
              </h3>
              <button
                onClick={() => setIsOpen(true)}
                style={{ transition: "0.5s" }}
                className="text-[20px] font-SF-Pro-Semibold text-white hover:text-black border-[2px] border-[#1aa37e] bg-[#1aa37e] hover:bg-white rounded-xl py-[10px] px-[60px]"
              >
                Get Started
              </button>
              {/* <Button5 text={"Partner With Us"} /> */}
            </div>
          </div>
        </section>

        {/* Flipping Card*/}
        <section className="container my-[4rem]">
          <div style={{ lineHeight: "1" }}>
            <h2 className="text-center text-[#000] py-0 my-0 font-SF-Pro-Regular font-semibold text-[26px] lg:text-[30px]">
              Why Choose Us?
            </h2>
            <p className="text-black leading-[1.4] mt-[15px] font-SF-Pro-Regular text-[20px] lg:text-[24px] text-center">
              {" "}
              Experience A World-Class Mobile App Development Service from Best
              <span className="text-[#337ab7]">
                {" "}
                Mobile App Development Company
              </span>
            </p>
          </div>
          <ul className="py-[2rem] over-rated star-rated mt-[2rem]">
            {FlipCardItems.map((item, index) => (
              // <div key={index}>
              <TransitionCard
                imgSrc={item.imgSrc}
                label={item.label}
                content={item.content}
              />
              // </div>
            ))}
          </ul>
        </section>

        {/* Custom Testimonial */}
        {/* <section className="container">
          <h2 className="text-[30px] font-bold my-[20px] text-center">
            Their Taste-Statement Make Us Proud
          </h2>
          
          <AppTestimonial />
        </section> */}
        {/* Short Info Blue BG*/}
        <section className="dot-bg-blue py-[50px] lg:mt-[5rem]">
          <div className="container text-white gap-[1rem]">
            <div className=" flex flex-col lg:flex-row items-center gap-y-[1.5rem]">
              <div className="w-full lg:w-[55%]">
                <h3
                  // style={{ fontWeight: "800" }}
                  className="text-center lg:text-left text-[26px] px-[20px] py-[12px] leading-10 font-SF-Pro-Regular"
                >
                  Let's Work Together to{" "}
                  <span className="font-SF-Pro-Semibold">
                    Build Your Dream App!
                  </span>
                </h3>
              </div>
              <div className="w-full lg:w-[45%] flex justify-center lg:justify-end">
                <button
                  onClick={() => setIsOpen(true)}
                  style={{ transition: "0.3s" }}
                  className="text-[18px] lg:text-[20px] text-white font-SF-Pro-Bold border-[2px] py-[13px] px-[60px] rounded-md hover:bg-white hover:text-black"
                >
                  Book A Free Demo
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* Clients*/}
        {/* <section className="container py-[40px] lg:py-[80px] flex flex-col gap-y-[2rem]">
          <div>
            <h2
              style={{ lineHeight: "1.2" }}
              className="text-center text-[#282828] py-0 my-0 font-SF-Pro-Semibold text-[26px] lg:text-[30px]"
            >
              Our Clients
            </h2>
            <p
              style={{ lineHeight: "1.1" }}
              className="text-black mb-[0.5rem] mt-[15px] font-SF-Pro-Regular text-[20px] lg:text-[24px] text-center"
            >
              {" "}
              We Have Made Them Taste Success
            </p>
          </div>
          <div className="grid grid-cols-3 lg:grid-cols-5 gap-[0.5rem]">
            {clientsToDisplay.map((client, index) => (
              <div
                style={{ transition: "0.3s" }}
                key={index}
                className="flex items-center justify-center py-[1rem] px-[0.5rem] cursor-pointer hover:scale-[1.1]"
              >
                <Framer>
                  <img className="w-[10rem]" src={client.imgSrc}></img>
                </Framer>
              </div>
            ))}
          </div>
        </section> */}

        {/* <section className="bg-[#2c2d78] partnership-independent-cta  text-center px-[15px] py-[75px]">
          <div className="container font-SF-Pro-Semibold text-white">
            <img
              className="w-[40px] mb-[20px] mx-auto"
              src="/assets/handshake-contact.png"
            ></img>
            <h5 className="text-[16px] lg:text-[20px]">Join, Sell & Earn</h5>
            <h4
              style={{ letterSpacing: "0.5px" }}
              className=" font-SF-Pro-Bold text-[18px] lg:text-[30px]"
            >
              Explore Our Partnership Program to Sell{" "}
              <br className="hidden lg:block" /> Our Fully Customized Tech
              Solution To Your Clients.
            </h4>
            <button
            onClick={()=>setIsOpen(true)}
              style={{ transition: "0.3s", borderRadius: "2px" }}
              className="font-SF-Pro-Regular ripple-effect-button overflow-hidden text-[18px] bg-[#70aa26] py-[10px] lg:py-[13px] pl-[38px] pr-[15px] lg:pl-[47px] lg:pr-[20px] text-white border-[1px] border-[#70aa26] hover:bg-[#71aa26d1]"
            >
              Partner With Us!
            </button>
          </div>
        </section> */}
        {/* 3 Review card */}

        <section className="py-[40px] lg:py-[80px]">
          <div className="container text-black text-center leading-5">
            <h4
              style={{
                fontWeight: "400",
                letterSpacing: "1px",
                lineHeight: "1.2",
              }}
              className="text-[26px] lg:text-[50px] font-SF-Pro-Bold"
            >
              WHAT OUR{" "}
              <span className="font-SF-Pro-Regular text-[#e14a15]">
                CLIENTS SAY
              </span>
            </h4>
          </div>
          <div className=" flex flex-wrap  justify-center mt-[2rem] lg:mt-[3.5rem] gap-x-[2rem] gap-y-[2rem]">
            <Marquee pauseOnHover autoFill={true} speed={60}>
              {GoogleReviews.map((review, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <MarqueeReviewCard
                    content={review.content}
                    clientImg={review.clientImg}
                    clientName={review.ClientName}
                  />
                </div>
              ))}
            </Marquee>
          </div>
        </section>
        <section className="bg-[#2919ff] dot-bg-blue py-[80px] ">
          <div className="container text-white text-center justify-center items-center flex flex-col gap-[1rem]">
            <div className=" flex flex-col items-center gap-y-[1.5rem]">
              <h3 className="text-[22px] lg:text-[26px] leading-[1.3] lg:leading-10 font-SF-Pro-Regular">
                Get A{" "}
                <span className="font-SF-Pro-Semibold">
                  Fully Customized Mobile App Development Services
                </span>{" "}
                To Suit Your Business Needs
              </h3>

              <button
                onClick={() => setIsOpen(true)}
                style={{ transition: "0.5s" }}
                className="text-[20px] text-white hover:text-black border-[2px] border-[#1aa37e] bg-[#1aa37e] hover:bg-white rounded-xl py-[15px] px-[60px]"
              >
                Book A Free Demo
              </button>
            </div>
          </div>
        </section>
        <ContactForm1 />
      </div>
      <ModalFormat isOpen={isOpen} onClose={closeModal}>
        <div className="mt-[5rem]">
          <ContactCard />
        </div>
      </ModalFormat>
      {/* <GetInTouch onClick={() => setIsOpen(true)} /> */}
    </>
  );
}
