import { Padding } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./css/phnInput.css";

export function PhoneNum({name,placeholder}) {
  return (
    <div className="flex justify-center">
      <PhoneInput
      name="from_phnNum"
        inputClass="phnInput adjustWidthPhn"
        country="in"
        placeholder="Phone"
        preferredCountries={["in"]}
        // preferredCountries="in"
        specialLabel={false}
        // inputStyle={{padding:"10px 65px", width:"30rem"}}
        containerStyle={{ display:"flex"}}
        // dropdownStyle={{ width: "30rem" }}
        dropdownClass="adjustWidthPhn"
        inputProps={{
          name: "from_phnNum",
          required: true,
          // autoFocus: true,
        }}
      />
    </div>
  );
}
export function PhoneNum1({placeholder}) {
  return (
    <div className="flex phoneNum1 justify-center">
      <PhoneInput
      name="from_phnNum"
        inputClass="phnInput1 adjustWidthPhn"
        country="in"
        placeholder="Phone"
        preferredCountries={["in"]}
        specialLabel={false}
        containerStyle={{ display:"flex"}}
        // dropdownStyle={{ width: "30rem" }}
        dropdownClass="adjustWidthPhn"
        inputProps={{
          name: 'from_phnNum',
          required: true,
          // autoFocus: true,
        }}
      />
    </div>
  );
}

export function PhoneNumFooter({placeholder}) {
  return (
    <div className="flex relative phoneNum1 phnInputFooter justify-center">
      <PhoneInput
      name="from_phnNum"
        inputClass="phnInput1 footerPhnInput"
        country="in"
        placeholder="Phone"
        preferredCountries={["in"]}
        specialLabel={false}
        containerStyle={{ display:"flex"}}
        // dropdownStyle={{ width: "30rem" }}
        dropdownClass="adjustWidthPhn"
        inputProps={{
          name: 'from_phnNum',
          required: true,
          // autoFocus: true,
        }}
      />
      
         <button  style={{borderRadius:"4px"}} className="text-[16px] absolute bg-[#70aa26] w-[90px] text-white h-full right-0"><a href="https://wa.me/919216041313/?text=Hello">Submit</a></button>
    </div>
  );
}

