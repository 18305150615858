import React from "react";

const InputField = ({ name, placeholder, id, border }) => {
  return (
    // <div className="relative rounded-md shadow-sm">
    <div class="inputfield relative">
      <input
        name={name}
        type="text"
        id={id}

        style={{ transition: "0.3s" }}
        // class="block font-GalanoRegular border px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1  appearance-none  border-gray-300 focus:border-blue-500  focus:outline-none focus:ring-0  peer"
        className={`form-control h-full text-black block w-full px-4 py-[0.7rem] ${border} border-gray-300 rounded-md focus:ring-0 focus:border-transparent `}
        placeholder={placeholder}
        autocomplete="off"
        aria-autocomplete="off"
        required
      />
      {/* <label
        for={id}
        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
      >
        {placeholder}
      </label> */}
    </div>

    // </div>
  );
};

export default InputField;
